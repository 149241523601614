import { DateControl, LightCurrency } from '@shared/models'

export interface AppCurrenciesState {
  loading: boolean
  error: string
  data: LightCurrency[]
  dateControl: DateControl
}

export const appCurrenciesInitialState: AppCurrenciesState = {
  loading: false,
  error: '',
  dateControl: new DateControl(new Date(), 0),
  data: []
}
