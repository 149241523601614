import { createFeature, createReducer, on } from '@ngrx/store'
import { appCurrenciesInitialState } from './currencies.state'
import { loadAppCurrenciesData, loadAppCurrenciesDataError, loadAppCurrenciesDataSuccess } from './currencies.actions'

const appCurrenciesReducer = createReducer(
  appCurrenciesInitialState,
  on(loadAppCurrenciesData, (state) => ({
    ...state,
    loading: true,
    error: ''
  })),
  on(loadAppCurrenciesDataSuccess, (state, { data, dateControl }) => ({
    ...state,
    loading: false,
    data,
    dateControl
  })),
  on(loadAppCurrenciesDataError, (state, { error }) => ({
    ...state,
    loading: false,
    error
  }))
)

export const appCurrenciesFeature = createFeature({
  name: 'app-currencies',
  reducer: appCurrenciesReducer
})
