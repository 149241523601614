import { DateControl, LightCurrency } from '@shared/models'
import { createAction, props } from '@ngrx/store'

export const currenciesStoreKey = 'App Currencies'

export const LOAD_APP_CURRENCIES_DATA = `[${ currenciesStoreKey }] Load data`
export const LOAD_APP_CURRENCIES_DATA_SUCCESS = `[${ currenciesStoreKey }] Load data success`
export const LOAD_APP_CURRENCIES_DATA_ERROR = `[${ currenciesStoreKey }] Load data error`

export const loadAppCurrenciesData = createAction(LOAD_APP_CURRENCIES_DATA)
export const loadAppCurrenciesDataSuccess = createAction(LOAD_APP_CURRENCIES_DATA_SUCCESS, props<{
  data: LightCurrency[]
  dateControl: DateControl
}>())
export const loadAppCurrenciesDataError = createAction(LOAD_APP_CURRENCIES_DATA_ERROR, props<{
  error: string
}>())
