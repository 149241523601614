import { Injectable } from '@angular/core'
// RxJS
import { exhaustMap, of, catchError, map } from 'rxjs'
// NgRx
import { Actions, createEffect, ofType } from '@ngrx/effects'
// Service
import { CurrenciesApiService } from '@services/api'
// Store
import { LOAD_APP_CURRENCIES_DATA, LOAD_APP_CURRENCIES_DATA_ERROR, LOAD_APP_CURRENCIES_DATA_SUCCESS } from './currencies.actions'
// Data
import { DEFAULT_NOTIFICATION_ERROR_MESSAGE } from '@shared/consts'
import { DateControl } from '@shared/models'
import { DATE_CONTROL_STALE_TIME } from './currencies.config'

@Injectable()
export class AppCurrenciesEffects {
  loadAppCurrencies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_APP_CURRENCIES_DATA),
      exhaustMap(() => this.api.getLightCurrencies().pipe(
        map(({ data }) => (data ? {
          type: LOAD_APP_CURRENCIES_DATA_SUCCESS,
          // Текущая дата, 5 минут
          dateControl: new DateControl(new Date(), DATE_CONTROL_STALE_TIME),
          data: data
        } : {
          type: LOAD_APP_CURRENCIES_DATA_ERROR,
          error: DEFAULT_NOTIFICATION_ERROR_MESSAGE
        })),
        catchError(({ error }) => of({
          type: LOAD_APP_CURRENCIES_DATA_ERROR,
          error: error?.message || DEFAULT_NOTIFICATION_ERROR_MESSAGE
        }))
      ))
    )
  )

  constructor (
    private readonly actions$: Actions,
    private readonly api: CurrenciesApiService
  ) {
  }
}
